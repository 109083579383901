import { useState } from "react";
import Fastblock from "../fastblock/Fastblock";
import Header from "../header/Header";
import Presentation from "../presentation/Presentation";
import Questions from "../questions/Questions";
import Service from "../service/Service";
import Professionals from "../professionals/Professionals";
import classes from "./../header/header.module.scss"
import Price from "../price/Price";
import Mape from "../map/Mape";
import Footer from "../footer/Footer";


function App() {
  const phoneNumber = '8 (499) 714-48-88'; 
  const [price, setPrice] = useState(false)
  const [modalopen, setModalopen] = useState(false)
  const isMobile = window.innerWidth <= 505;
  const scrollToTop = () => {
    // Получаем элемент документа
    const documentElement = document.documentElement;
    // Получаем текущее положение прокрутки
    // const scrollTop = documentElement.scrollTop;
    // Скроллим страницу вверх
    documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  };

  function openModal() {
    setModalopen(true);
    setPrice(false);
    document.body.style.overflow = 'hidden';
  }
  <div className={classes.cardImg + " " + classes.one}></div>
  return (
    <div className="App">
        <div className = {classes.niz}>
          {/* <button onClick={openModal} className = {classes.btn12} >Оставить заявку</button> */}
          <a className = {classes.btn11} href={`tel: ${phoneNumber}`}>Позвонить</a>
        </div>
        <button className= {price? (classes.up_btn + " " + classes.blue) : (classes.up_btn + " " + classes.orange)} onClick={()=>scrollToTop()} > <div className={classes.arrow}><img  src={require("./../questions/chevron-up.webp")} alt="arrow"></img></div> </button>

      {price ? <Price setPrice={setPrice}/>
     :(
      <>  
      <header>
    <Header phoneNumber = {phoneNumber}/>
      </header>
      <div className= {classes.fix}>
    <a className= {classes.calk_btn} href={`tel: ${phoneNumber}`}>Позвонить</a>
		<button className= {classes.price_btn} onClick={()=>setPrice(true)} >Прайс</button>
	</div>
   
    <Presentation phoneNumber = {phoneNumber} modalopen={modalopen}  openModal={openModal} setModalopen={setModalopen}/>
    <Fastblock/>
    <Service  openModal={openModal}/>
    <Professionals phoneNumber= {phoneNumber} modalopen={modalopen}  openModal={openModal} setModalopen={setModalopen}/>
    <Questions/>
    <Mape phoneNumber = {phoneNumber} />
    <Footer isMobile={isMobile}/>
    </>)
      }
    </div>
  );
}
export default App;
